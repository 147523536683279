import React, { Component } from "react"
import PropTypes from "prop-types"

import Layout from "../layouts"
import Components from "../components"

class StoryblokEntry extends Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null
    }

    return StoryblokEntry.prepareStory(props)
  }

  static prepareStory(props) {
    const { pageContext } = props
    const story = Object.assign({}, pageContext.story)
    story.content = JSON.parse(story.content)

    return { story }
  }

  constructor(props) {
    super(props)

    this.state = StoryblokEntry.prepareStory(props)
  }

  render() {
    const { story } = this.state
    const { location } = this.props

    return (
      <Layout>
        {React.createElement(Components(story.content.component), {
          key: story.content._uid,
          blok: story.content,
          path: location.pathname,
          story: story
        })}
      </Layout>
    )
  }
}

StoryblokEntry.propTypes = {
  pageContext: PropTypes.shape({
    story: PropTypes.object,
    global: PropTypes.object,
  }).isRequired,
}

export default StoryblokEntry
